<template>
  <div
    class="container noColor"
    v-title
    :data-title="$t('i18n.floorsInfo')"
    id="floorsInfo"
  >
    <el-card class="box-card">
      <template #header>
        <span>{{ $t("i18n.basicInfo") }}</span>
        <el-button class="fr" type="text" @click="$router.go(-1)">
          {{ $t("i18n.back") }}
        </el-button>
      </template>
      <div class="text item">
        <jl-form :columns="basicColumns" :option="btnOption"></jl-form>
      </div>
    </el-card>
    <el-card class="box-card m-t">
      <div class="text item" id="width" style="width: 60%">
        <jl-table
          :tableData="tableData"
          @init="init"
          @handleEdit="handleEdit"
          @handleDelete="handleDelete"
          :options="options"
          :columns="columns"
          :operates="operates"
          :height="$defined.HEIGHT - 360 + 'px'"
          @handleSelectionChange="handleSelectionChange"
        >
        </jl-table>
      </div>
    </el-card>
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import jlForm from "../../components/form";
import jlTable from "../../components/table";
export default {
  name: "FloorCoU",
  components: {
    jlForm,
    jlTable,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const { t } = useI18n();
    const route = useRoute();
    const state = reactive({
      paging: {
        page: 1,
        pageSize: 10,
      },
      form: {},
      basicColumns: [
        {
          prop: "number",
          label: "elevatorNo",
          type: "input",
          readonly: true,
        },
        {
          prop: "projectName",
          label: "projectName",
          type: "input",
          readonly: true,
        },
        {
          prop: "station",
          label: "floorsNum",
          type: "input",
          readonly: true,
        },
      ],
      elevatorDetail: "",
      btnOption: [
        {
          show: false,
        },
      ],
      tableData: [],
      total: 0,
      options: {
        // table 的参数
        isAdd: true, // 是否启用新增功能
        isEdit: true, // 是否启用编辑功能
        isDelete: true, // 是否启用删除功能
        highlightCurrentRow: false, // 是否支持当前行高亮显示
        multiSelect: false, // 是否支持列表项选中功能
        pageShow: false, // 是否翻页
      },
      columns: [
        // 需要展示的列
        {
          prop: "realFloor",
          label: "physicalFloor",
          align: "center",
          type: "input",
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "displayFloor",
          label: "disFloor",
          align: "center",
          type: "input",
          rules: [{ required: true, message: t("i18n.input") }],
        },
      ],
      operates: {
        // 列操作按钮
        width: 140,
        fixed: "right",
        list: [],
      },
      selectItem: [],
    });

    const init = async () => {
      state.loading = true;
      let id = route.query.id;
      const { data } = await proxy.$api.system.getElevatorDetail(id);
      state.tableData = data.elevatorFloorCompareDTOList.sort(
        upSort("realFloor")
      );
      state.basicColumns.map((item) => {
        item.value = data[item.prop];
      });
      state.loading = false;
    };

    const upSort = (key) => {
      //指定某个属性的排序
      return function (a, b) {
        return a[key] - b[key];
      };
    };

    const handleEdit = async (val) => {
      // 新增/编辑数据
      let msg = val.id ? "edit" : "new";
      val.elevatorId = route.query.id;
      val.id
        ? await proxy.$api.system.editFloor(val)
        : await proxy.$api.system.addFloor(val);
      proxy.$defined.tip(t("i18n." + msg) + t("i18n.success"), "success");
      // 初始化 刷新
      init();
    };

    const handleDelete = (row) => {
      proxy.$api.system.delFloor(row.id).then(() => {
        init();
        proxy.$defined.tip(t("i18n.delete") + t("i18n.success"), "success");
      });
    };

    return {
      ...toRefs(state),
      init,
      handleEdit,
      handleDelete,
    };
  },
};
</script>

<style lang="scss">
#floorsInfo {
  .form-button {
    width: 100%;
    margin: 0;
    text-align: center;
  }
}
@media screen and (max-width: 768px) {
  #floorsInfo {
    margin: -20px 5px 0;
    #width {
      width: 100% !important;
    }
  }
}
</style>
